import 'slick-carousel';

$(document).ready(function () {
	if ($('.docs__list').length) {
		$('.docs__list').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			//variableWidth: true,
			infinite: true,
			rows: 0,

			prevArrow: '<button type="button" class="slick-prev"><svg class="arr_red" version="1.1" width="9" height="17" viewBox="0 0 9 17"><path fill="#D8232A" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg><svg class="arr_white" version="1.1" x="0px" y="0px" viewBox="0 0 9 17" width="9" height="17"><path fill="#FFFFFF" d="M0.1,8.8l8,8C8.3,17,8.4,17,8.5,17c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L1.2,8.5l7.7-7.7C9,0.6,9,0.3,8.9,0.1S8.4,0,8.2,0.1l-8,8C0,8.3,0,8.7,0.1,8.8z"/></svg></button>',
			nextArrow: '<button type="button" class="slick-next"><svg class="arr_red" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#D8232A" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg><svg class="arr_white" width="9px" height="17px" viewBox="0 0 9 17"><path fill="#ffffff" d="M8.861192,8.16110442 L0.82390251,0.141614712 C0.634663797,-0.0472049039 0.331167748,-0.0472049039 0.141929035,0.141614712 C-0.0473096783,0.330434327 -0.0473096783,0.633258239 0.141929035,0.822077854 L7.83644652,8.49955467 L0.141929035,16.1770315 C-0.0473096783,16.3658511 -0.0473096783,16.668675 0.141929035,16.8574946 C0.23476312,16.9501231 0.359732082,17 0.481130502,17 C0.602528921,17 0.727497883,16.9536858 0.820331968,16.8574946 L8.85762146,8.83800492 C9.04686017,8.65274794 9.04686017,8.3463614 8.861192,8.16110442 Z"></path></svg></button>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: false,
						dots: true
					}
				}, {
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						dots: true
					}
				}
			]
		});
	}
});
